.p-calendar {
  width: 100%;
}

.p-datepicker.p-component.p-connected-overlay-enter-done {
  width: 350px !important;
  min-height: 165px;
  z-index: 2;
}

.p-datepicker:not(.p-datepicker-inline) {
  border: solid 1px #a4b3b7;
  border-radius: 2.5rem;
  font-size: 2.5rem;
  z-index: 2 !important;
  width: 350px !important;
  min-height: 165px;
}

.p-datepicker-header {
  button.p-link {
    font-size: 2rem;
  }
}

.p-datepicker-inline {
  border: 1px solid #a4b3b7;
  border-radius: 2.5rem;
}

.p-datepicker table {
  font-size: 1.5rem;
}

.p-inputtext {
  border: solid 1px #a4b3b7;
  border-radius: 1.5rem;
  padding: 0.625rem 1.375rem;
  line-height: 1.5;
  font-size: 1.5rem;
  background-color: transparent;
}

.p-inputtext:enabled:focus {
  border-color: #a4b3b7;
  box-shadow: none;
}

.p-inputtext:enabled:hover {
  border-color: #a4b3b7;
}

.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year:enabled:hover,
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month:enabled:hover {
  color: #4798b6;
}