*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

ol, ul {
  list-style: none;
}

a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

::-webkit-scrollbar { 
  display: none; 
}

button{
  outline: none;
}