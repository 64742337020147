$theme_primary: #4798b6;

h1.contents {
  color: $theme_primary;
  text-decoration: underline;
  margin-bottom: 2rem;
}

p.contents {
  margin-bottom: 2rem;
}

h4.contents {
  color: $theme_primary;
}

a.contents, .subtitle-contents {
  color: $theme_primary;
  text-decoration: underline;
}

.subtitle-contents, .black-subtitle-contents {
  margin-bottom: 1rem;
  font-weight: bold;
}

.black-subtitle-contents {
  text-decoration: underline;
}

.lists {
  margin-left: 2rem;
}

.sublists {
  margin-left: 4rem;
}

.sublists2 {
  margin-left: 6rem;
}

#emails-wrapper {
  display: flex;
  width: 100%;
  div {
    display: flex;
    flex-direction: column;
    width: fit-content;
    padding: 1rem;
    line-height: 1.5;
  }
}

.legal-img {
  margin-left: auto;
  margin-right: -10rem;
  width: 15rem;
  height: 15rem;
  background-size: cover;

  &#terms {
    background-image: url("/images/legal/terms.png");
  }

  &#privacy {
    background-image: url("/images/legal/privacy.png");
  }
}