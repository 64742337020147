html {
  font-size: 62.5%; // 1rem = 10px; 10px/16px = 62.5%
  font-family: $primary-font;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;

  // @include respond(tab-land){
  //   font-size: 56.25%; // 1 rem = 9px; 9px/16px = 56.25%
  // }

  // @include respond(tab-port){
  //   font-size: 50%; // 1 rem = 8px; 8px/16px = 50%
  // }

  @include respond(big-desktop){
    font-size: 75%; // 1 rem = 12px; 12px/16px = 75%
  }
}

body {
  box-sizing: border-box;
  font-size: 1.4rem;
  font-family: $primary-font;
  color: $text-primary;
}

.main-container{
  position: relative;
}

button, input, select, option {
  font-family: $primary-font;
}